var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [_vm._m(0), _vm._v(" "), _c("ve-line", { attrs: { data: _vm.chartData } })],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "warn-content" }, [
      _c("a", { attrs: { target: "_blank" } }, [_vm._v("数据/时间 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }