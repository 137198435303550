var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-header",
        { staticStyle: { height: "auto" } },
        [
          _c(
            "el-row",
            { staticStyle: { margin: "20px 0px" }, attrs: { gutter: 24 } },
            [_c("Head", { attrs: { name: _vm.titled } })],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticClass: "btn",
              staticStyle: { margin: "10px 0px" },
              attrs: { gutter: 24 },
            },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "padding-left": "0", "margin-right": "15px" },
                  attrs: { xs: 24, sm: 12, md: 9, lg: 8, xl: 8 },
                },
                [
                  _vm._v("\n                时间筛选:\n                "),
                  _c("el-date-picker", {
                    staticStyle: { width: "250px", "margin-top": "10px" },
                    attrs: {
                      type: "daterange",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "timestamp",
                      size: "small",
                      "default-time": ["00:00:00", "23:59:59"],
                    },
                    on: { change: _vm.DataTime },
                    model: {
                      value: _vm.value1,
                      callback: function ($$v) {
                        _vm.value1 = $$v
                      },
                      expression: "value1",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-main",
        [
          _c("nx-data-tabs", {
            staticStyle: { margin: "10px 0px" },
            attrs: { option: _vm.easyDataOption },
          }),
          _vm._v(" "),
          _c("ve-line", { attrs: { data: _vm.chartData } }),
          _vm._v(" "),
          [
            _c(
              "el-table",
              {
                attrs: {
                  data: _vm.tableData,
                  width: "100%",
                  border: "",
                  "row-key": "id",
                  "tree-props": { children: "children" },
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    label: "下级",
                    width: "150",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  { attrs: { label: "基础指标", align: "center" } },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "private_sea", label: "总数据" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "untreated_num_sum", label: "未回访" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  { attrs: { label: "数据来源", align: "center" } },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "create_num", label: "创建" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "pull_num", label: "领取" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "be_shared_num", label: "被共享" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "allot_num", label: "被分配" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  { attrs: { label: "跟进指标", align: "center" } },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "follow_num", label: "跟进数" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "success_num", label: "报名成交数" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  { attrs: { label: "均值指标", align: "center" } },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "send_out_num",
                        label: "外送公海",
                        width: "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "back_num",
                        label: "回归公海",
                        width: "120",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }