//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: function data() {
    return {
      chartData: {
        columns: ['日期', '访问用户', '下单用户', '下单率'],
        rows: [{
          日期: '1',
          访问用户: 1393,
          下单用户: 1093,
          下单率: 1098
        }, {
          日期: '2',
          访问用户: 3530,
          下单用户: 3230,
          下单率: 3238
        }, {
          日期: '3',
          访问用户: 2923,
          下单用户: 2623,
          下单率: 2624
        }, {
          日期: '4',
          访问用户: 1723,
          下单用户: 1423,
          下单率: 0.49
        }, {
          日期: '5',
          访问用户: 3792,
          下单用户: 3492,
          下单率: 0.323
        }, {
          日期: '6',
          访问用户: 4593,
          下单用户: 4293,
          下单率: 0.78
        }, {
          日期: '7',
          访问用户: 1393,
          下单用户: 1093,
          下单率: 1098
        }, {
          日期: '8',
          访问用户: 3530,
          下单用户: 3230,
          下单率: 3238
        }, {
          日期: '9',
          访问用户: 2923,
          下单用户: 2623,
          下单率: 2624
        }, {
          日期: '10',
          访问用户: 1723,
          下单用户: 1423,
          下单率: 0.49
        }, {
          日期: '11',
          访问用户: 3792,
          下单用户: 3492,
          下单率: 0.323
        }, {
          日期: '12',
          访问用户: 4593,
          下单用户: 4293,
          下单率: 0.78
        }]
      }
    };
  }
};