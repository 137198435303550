import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.object.values";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import nxDataTabs from '@/components/nx-data-tabs/nx-data-tabs';
import DiscountFigure from '@/views/charts/DiscountFigure';
import Head from '@/components/head/index';
import { MyLoweList, getStructureListArr } from '@/api/user_restPwd/Managesubordinates/department';
export default {
  components: {
    Head: Head,
    nxDataTabs: nxDataTabs,
    DiscountFigure: DiscountFigure
  },
  data: function data() {
    return {
      loading: true,
      //折线图数据
      chartData: {
        columns: ['日期', '未回访总量', '被共享', '创建的'],
        rows: []
      },
      titled: '数据统计',
      value1: '',
      bumenoptions: [],
      //部门
      customer_level: '',
      //级别
      customer: [],
      bumen: '',
      //部门
      count: {},
      //卡片数据
      easyDataOption: {
        style: 'width:20%',
        span: 4,
        data: [{
          title: '未回访',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/1095ff91752325f2b5760858ab32bed1.jpg',
          key: 'icon-huifang'
        }, {
          title: '被共享',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/7126855260e360f0d07aeec2367b21b6.jpg',
          key: 'icon-gongxiang1'
        }, {
          title: '创建数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/4885d217e52f37acd8bfae188346fb30.jpg',
          key: 'icon-chuangjian'
        }, {
          title: '跟进数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/2cbda790813b1017465cccf9a162afc6.jpg',
          key: 'icon-genjin'
        }, {
          title: '报名成交数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/2bdf5eccfc829372f28edb8f90b605f4.jpg',
          key: 'icon-shichangchengjiaoshuju'
        }, {
          title: '回归共海数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/de58e9576c4d4f63528e215ef15b8d9b.jpg',
          key: 'icon-huiguijianmo'
        }, {
          title: '分配数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/7126855260e360f0d07aeec2367b21b6.jpg',
          key: 'icon-daifenpeifuwushang'
        }, {
          title: '领取数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/1095ff91752325f2b5760858ab32bed1.jpg',
          key: 'icon-lingqu'
        }, {
          title: '外送公海池数',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/4885d217e52f37acd8bfae188346fb30.jpg',
          key: 'icon-waisongdanweiguanli'
        }, {
          title: '私海总数据量',
          count: 0,
          imgUrl: 'https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/2bdf5eccfc829372f28edb8f90b605f4.jpg',
          key: 'icon-zongshu1'
        }]
      },
      tableData: [],
      start_time: '',
      end_time: ''
    };
  },
  created: function created() {
    this.GetList();
    this.getbumenArr();
  },
  methods: {
    GetList: function GetList() {
      var _this = this;
      MyLoweList({
        start_time: this.start_time,
        end_time: this.end_time
      }).then(function (respomse) {
        //折线图数据处理
        _this.chartData.rows = respomse.data.statistics_obj_group.map(function (item, index) {
          return {
            日期: item.data_time,
            被共享: item.be_shared_num,
            创建的: item.create_num,
            未回访总量: item.untreated_num_sum
          };
        });
        _this.easyDataOption.data.map(function (item, index) {
          item.count = Object.values(respomse.data.sum)[index];
        });
        _this.tableData = respomse.data.structure_arr;
        _this.loading = false;
      });
    },
    getbumenArr: function getbumenArr() {
      var _this2 = this;
      getStructureListArr().then(function (res) {
        _this2.bumenoptions = res.data;
      });
    },
    DataTime: function DataTime() {
      var _this3 = this;
      this.NumberEs = Number(0);
      if (this.value1 !== null) {
        var s_time = this.value1[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.value1[1].toString();
        this.end_time = e_time.substring(0, 10);
        this.$nextTick(function () {
          _this3.GetList();
        });
      } else {
        this.$nextTick(function () {
          _this3.GetList();
        });
      }
    },
    //bumen
    handleChange: function handleChange(value) {
      var _this4 = this;
      if (value.length == 0) {
        this.uid = '';
        this.$nextTick(function () {
          _this4.GetList();
        });
      } else {
        var lastElement = value[value.length - 1].toString();
        this.uid = lastElement;
        this.$nextTick(function () {
          _this4.GetList();
        });
      }
    }
  }
};